@font-face {
  font-family: 'HelveticaT';
  font-style: normal;
  src: local('Helvetica'), local('Helvetica'),
  url('./fonts/Helvetica-Oblique.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/Helvetica-Oblique.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/Helvetica-Oblique.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/Helvetica-Oblique.ttf') format('truetype') /* Safari, Android, iOS */
}

body {
  margin: 0;
  font-family: 'HelveticaT' !important;
}

.bgProperties {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.bgHome {
  background: #ffffff;
}

.bgAbout {
  background: #ffffff;
}

.bgService {
  background: #ffffff;
}

.bgCatalog {
  background: #ffffff;
}

.bgContact {
  background: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
