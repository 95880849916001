/*Extra small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
  .hideOnSmallerScreen{
    display: none !important;
  }
  .showOnSmallerScreen{
    display: block !important;
  }
  .footerBarRP{
    padding-right: 0px !important;
  }
  .removeSidePaddingContent{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

/*Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {
  .hideOnSmallerScreen{
    display: none !important;
  }
  .showOnSmallerScreen{
    display: block !important;
  }
  .footerBarRP{
    padding-right: 0px !important;
  }
  .removeSidePaddingContent{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

/*Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) {
  .hideOnSmallerScreen{
    display: none !important;
  }
  .showOnSmallerScreen{
    display: block !important;
  }
  .footerBarRP{
    padding-right: 0px !important;
  }
  .removeSidePaddingContent{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
/************Responsive Styles****************************/
.separateSN{
  margin: 0 2% !important;
}

.iconRp{
  width: 24px !important;
  height: 24px !important;
}
footHomeIconRp{
  font-size: 24px;
}
.footPageTextRp{
  font-size: 8px !important;
  margin-bottom: 0px !important;
}
.iconHeaderRp{
  width: 32px !important;
  height: 24px !important;
}
.iconOptionRp{
  width: 32px !important;
  height: 32px !important;
  margin-top: 15% !important;
}
.iconListRp{
  padding-left: 30px !important;
}
/***************************************/

.contentTitle {
  text-align: center;
}

.flexBoxContent {
  float: none;
  margin: 150px auto;
}

.required {
  color: #f00;
}

.footerOptionMenu{
  display: block;
  margin: auto 45%;
}

.mainContainer{
  overflow-y: auto;
  padding-top: 40pt !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.headerAlign {
  padding-left: 0.5pt !important;
  padding-right: 0.5pt !important;
}

.pointer{
  cursor: pointer;
}
/**Main Styles*/
.logo{
  width: 150px !important;
}
.mainBgColor{
  background-color: #003366 !important;
}
.mainColor{
  color: #ffffff !important;
}
.mainTextColor{
  color: #003366 !important;
}
.textHeader{
  font-size: 12px !important;
}
.subHeaderColor{
  background-color: #dadada !important;
}
.textBody{
  text-align: left;
  font-style: oblique;
}
.textBodyColor{
  color: #0b3754 !important;
}
.textSecondaryColor{
  color: #FFB900 !important;
}
.icon{
  width: 42px !important;
  height: 42px !important;
}
.iconHeader{
  width: 24px !important;
  height: 24px !important;
}
.iconSeparation{
  margin: 0 5% !important;
}
.iconSeparationHead{
  margin: 0 2% !important;
}
.footPageText{
  text-align: center !important;
  font-size: 14px !important;
  margin-bottom: 0px !important;
  padding-right: 20px;
}
.fontsize10{
  font-size: 10px !important;
}
.fontSize14{
  font-size: 14px !important;
}
.fontSize18{
  font-size: 18px !important;
}
.fontSize22{
  font-size: 22px !important;
}
.separateSocialNetwork{
  margin: 0 3% !important;
}
.footHomeIcon{
  font-size: 32px;
  padding-top: 30px !important;
  padding-left: 30px !important;
}
.dropdown-toggle{
  color: #003366 !important;
}
.navbar-nav > div{
  padding: 0 50px !important;
}
.navbar-nav > div:nth-child(5){
  padding-right: 0 !important;
  padding-left: 50px !important;
}
.dropdown-menu {
  background-color: #ffffff !important;
  border: 1px solid #eeeeee;
  box-shadow: 5px 5px 20px #333333;
  width: 280px !important;
  height: 315px !important;
}
.dropdown-menu > a {
  color: #003366 !important;
  font-size: 18px;
  font-weight: bold;
  padding: 5px 20px;
}
.dropdown-menu > a:hover {
  background-color: #003366 !important;
  color: #ffffff !important;
  border-radius: 25px !important;
}
#dropdown-button-drop-right{
  background-color: #dadada !important;
  border-color: #dadada !important;
  font-size: 22px !important;
  margin-top: 25% !important;
  padding-left: 0 !important;
}
.navFooter{
  padding: 3px !important;
  color: #ffffff !important;
}
/*************/
.nav-pills .nav-link.active, .nav-pills .show>.nav-link{
  background-color: #ffb900 !important;
  border-radius: 45px;
}

.carousel-indicators li{
  background-color: #000 !important;
}
/**image zoom*/
.react-simple-image-viewer__modal{
  z-index: 999999 !important;
}
input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}